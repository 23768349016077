@media (max-width: 1650px){
    .main_header_container{
        height: 500px;
        gap: 30px;
        padding: 30px 30px;
        background-image: url("../../assets/backgroundWhite1000.svg");
    }

    .main_header_text{
        width: 434px;
    }
    
    .main_header_text p{
        font-size: 20px;
    }
    
    .main_header_text h1{
        font-size: 40px;
    }

    .main_header_container button{
        border-radius: 60px;
        width: 256px;
        height: 63px;
        font-size: 25px;
    }

    .main_control_button.Logo{
        width: 172px;
        height: 55px;
    }
    
    .main_control_button.Cart{
        width: 34.13px; 
        height: 30px;
    }
    
    .main_control_button.Phone{
        width: 30.11px; 
        height: 30px;
    }
    
    .main_header_white{
        position: absolute;
        top: 0;
        left: 40%;
        z-index: 0;
        
        height: 500px;
    }

    .main_header_buttons_wrapper{
        width: 54.6%;
    }

    .main_header_isometric{
        width: 399px;
        height: 370px;
        top: 84px;
        left: auto;
        right: 20px;
    }

    .main_header_down{
        position: absolute;
        left: 0;
        top: 308px;

        width: 284px;
        height: 192px;
    }

    .main_header_top{
        position: absolute;
        width: 250px;
        height: 77px;
        top: 0;
        right: 562px;
    }

    .main_info_container{
        height: 220px;
    }
    
    .main_info_container p{
        font-size: 20px;
        width: 534px;
        margin-left: 12.2%;
    }

    .main_info_flag{
        width: 170px;
        height: 199px;
        right: 122.8px;
    }

    .main_info_down{
        bottom: -30px;
        left: -55px;
        width: 155px;
        height: 96px;
    }

    .main_warning_container{
        height: 271px;
        gap: 20px;
    }
    
    .main_warning_container p{
        margin-left: auto;
        margin-right: 180px;
        width: 456px;
        font-size: 20px;
    }

    .main_warning_sign{
        top: 50px;
        left: 125px;
        width: 188px;
        height: 171px;
    }

}

@media (max-width: 1000px){
    .main_header_container{
        height: 500px;
        gap: 50px;
        padding: 30px 30px;

        background-image: url("../../assets/backgroundWhite760.svg");
    }

    .main_header_text{
        width: 402px;
    }
    
    .main_header_text p{
        font-size: 18px;
    }
    
    .main_header_text h1{
        font-size: 38px;
    }

    .main_header_container button{
        border-radius: 60px;
        width: 256px;
        height: 63px;
        font-size: 25px;
    }

    .main_control_button.Logo{
        width: 172px;
        height: 55px;
    }
    
    .main_control_button.Cart{
        width: 34.13px; 
        height: 30px;
    }
    
    .main_control_button.Phone{
        width: 30.11px; 
        height: 30px;
    }
    
    .main_header_white{
        position: absolute;
        top: 0;
        left: 45%;
        z-index: 0;
        
        height: 500px;
    }

    .main_header_buttons_wrapper{
        width: 75.8%;
    }

    .main_header_isometric{
        width: 297px;
        height: 275px;
        top: unset;
        bottom: 26px;
        right: -7px;
    }

    .main_header_top{
        right: 322px;
    }

    .main_info_container{
        height: 170px;
    }
    
    .main_info_container p{
        font-size: 18px;
        width: 481px;
        margin-left: 40px;
    }

    .main_info_flag{
        width: 133px;
        height: 157px;
        right: 40px;
    }

    .main_info_right{
        width: 221px;
        height: 170px;
    }

    .main_svg_container svg{
        height: 196px;
    }

    .main_warning_container{
        height: 207px;
    }
    
    .main_warning_container p{
        margin-left: auto;
        margin-right: 40px;
        width: 456px;
        font-size: 18px;
    }

    .main_warning_sign{
        top: 30px;
        left: 40px;
        width: 162px;
        height: 147px;
    }
}

@media (max-width: 760px){
    .main_header_container{
        align-items: center;
        height: 706px;
        gap: 20px;
        padding: 20px 20px;

        background-image: url("../../assets/backgroundWhite480.svg");
        background-position: center 96px;
    }

    .main_header_isometric{
        width: 270px;
        height: 249px;
        top: unset;
        top: 119px;
        left: 50%;
        transform: translateX(-50%);
    }

    .main_header_text{
        width: 100%;
        margin-top: 317px;
        gap: 10px;
    }
    
    .main_header_text p{
        font-size: 15px;
    }
    
    .main_header_text h1{
        font-size: 30px;
    }
    
    .main_header_white{
        position: absolute;
        top: 0;
        left: 45%;
        z-index: 0;
        
        height: 500px;
    }

    .main_header_buttons_wrapper{
        width: 100%;
    }

    .main_control_buttons{
        gap: 20px;
    }

    .main_header_down{
        position: absolute;
        left: 0;
        top: 568.73px;

        width: 232px;
        height: 138px;
    }

    .main_header_top{
        display: none;
    }

    .main_info_container{
        height: 361px;
        align-items: center;
        justify-content: center;
    }
    
    .main_info_container p{
        font-size: 15px;
        width: 257px;
        margin-left: unset;
        margin-top: 187px;
    }

    .main_info_flag{
        width: 133px;
        height: 157px;
        left: 50%;
        transform: translateX(-50%);
    }

    .main_svg_container svg{
        top: 50%;
        left: 64%;
        transform: translate(-50%, -50%);
        height: 170px;
    }

    .main_warning_container{
        height: 343px;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
    }
    
    .main_warning_container p{
        margin-left: unset;
        margin-right: unset;
        width: 231px;
        font-size: 15px;
    }

    .main_warning_container{
        justify-content: flex-end;
        padding-bottom: 30px;
    }

    .main_warning_sign{
        top: 30px;
        left: 50%;
        transform: translateX(-50%);
        width: 161px;
        height: 147px;
    }

    .DeliveryBanner{
        font-size: 15px;
    }
    
    .DeliveryBanner button{
        font-size: 15px;
    }
    
}