.product_wrapper_add{
    border: 3px solid #6da6f7;
    border-radius: 11px;
    padding: 25.5px 16px;
    width: 241px;
    height: 376px;

    gap: 10px;

    box-shadow: 0 10px 17px 0 rgba(62, 100, 154, 0.25);
    background: #fff;

    font-weight: 700;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.product_wrapper_add img{
    border: 3px solid #6da6f7;
    border-radius: 11px;
    width: 194px;
    height: 194px;
    object-fit: cover;
}

.product_title{
    width: 194px;
    height: 54px;

    font-size: 15px;
    text-align: center;
    color: #6da6f7;

    display: flex;
    justify-content: center;
    align-items: center;
}

.product_price_add{
    width: 100%;
    height: 19px;

    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.product_wrapper_add button{

    background: none;
    outline: none;
    cursor: pointer;
    border: none;
}

.product_price_container{
    font-weight: 800;
    font-size: 15px;
    text-align: center;
    color: #6da6f7;
}

.product_change_quantity{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
}

.product_change_quantity button{
    padding: 0 5px;
    border-radius: 7px;
    border: none;
    background: #6da6f7;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.product_input{
    width: 141px;
    height: 28px;

    border-radius: 9px;
    width: 135px;
    height: 22px;

    font-weight: 400;
    font-size: 15px;
    color: #6da6f7;

    border: 3px solid #6da6f7;
    text-align: center;
}

.img_input{
    position: absolute;
    width: 194px !important;
    height: 194px !important;
    top: 16px;
    left: 25.5px;

    opacity: 0;
}

.cart_button_reduct{
    font-weight: 400;
    font-size: 15px;
    color: #6da6f7;
}

.product_wrapper_add input{
    width: 100%;
    height: 22px;

    border-radius: 9px;

    font-weight: 400;
    font-size: 15px;
    color: #6da6f7;

    border: 3px solid #6da6f7;
    text-align: center;

    outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.product_wrapper_add_cross{
    position: absolute;
    top: 5px;
    right: 5px;
}

.product_wrapper_add_cross svg{
    fill: #6da6f7;
}