.auth_wrapper{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth_container{
    border-radius: 30px;
    padding: 30px 60px;
    width: 600px;
    height: 350px;

    background: #6DA6F7;

    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 11px 21px 0 rgba(62, 100, 154, 0.25);

    gap: 30px;
}


.auth_container button{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 60px;
    width: 308px;
    height: 76px;
    box-shadow: 0 17px 18px 0 rgba(62, 100, 154, 0.5);
    background: #f86c6f;

    text-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
    font-weight: 700;
    font-size: 30px;
    color: #fff;
    z-index: 1;

    outline: none;
    border: none;
    cursor: pointer;

    padding: 20px 70px;
}

.auth_container button:active{
    box-shadow:none;
}

.InputTypePasswordWrapper{
    position: relative;
    width: 100%;
}

.ButtonEye{
    border: none;
    background: none;

    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 8px;

    transition: 0.5s;
}



.InputTypePassword{
    box-shadow: 0 0 0 0 rgba(27, 37, 39, 0.05), 0 5px 12px 0 rgba(27, 37, 39, 0.05), 0 22px 22px 0 rgba(27, 37, 39, 0.04), 0 49px 30px 0 rgba(27, 37, 39, 0.03), 0 88px 35px 0 rgba(27, 37, 39, 0.01), 0 137px 38px 0 rgba(27, 37, 39, 0);
    background: #fff;

    border: none;
    border-radius: 1000000000px;

    width: 100%;
    height: 32px;

    padding: 15px;

    padding-right: 30px;

    font-weight: 700;
    font-size: 15px;
    color: #6da6f7;
}

.InputTypePassword:focus{
    outline: none;
}

.ButtonEye svg{
    fill: #6DA6F7;
}

.auth_inputs{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    gap: 15px;

}

.error{
    border: 2px solid #f86c6f !important;
}