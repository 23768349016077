.modal_wrapper{
    position: fixed;
    top: -100vh;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.modal_wrapper.show{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    z-index: 10;

}

.modal_background{
    position: absolute;
    width: 100%;
    height: 100%;
    background: #d9d9d9;
    opacity: 0.5;
}

.cart_product_wrapper{

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: absolute;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 30px;
    padding: 30px 0px 0px 0px;
    width: 600px;
    height: 650px;

    gap: 30px;

    padding-top: 85px;
    padding-bottom: 24px;
    padding-inline: 50px;

    background: #fff;
}

.cart_product.empty p{
    font-weight: 700;
    font-size: 30px;
    color: #6da6f7;
    text-align: center;
}

.cart_product_cross{
    position: absolute;
    top: 30px;
    right: 50px;
    width: 28px;
    height: 26px;
    background: #6da6f7;
    border-radius: 7px;
    border: none;

    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart_product_cross svg{

    fill: white;
}

.cart_product.empty{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
}

.cart_product.full{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    border: 2px solid #6da6f7;
    border-radius: 15px;
    padding: 18px 18px 18px 20px;
    width: 100%;
    gap: 15px;
}

.cart_product_cross_blue{
    position: absolute;
    top: 18px;
    right: 24px;
    width: 16px;
    height: 17px;

    border: none;
    background: none;
    outline: none;
    cursor: pointer;
}

.cart_product_cross_blue svg{
    fill: #6da6f7;
}

.cart_product p{
    font-weight: 700;
    font-size: 15px;
    color: #6da6f7;
}

.cart_product a{
    font-weight: 700;
    font-size: 15px;
    color: #6da6f7;
}

.poducts_cart_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 30px;

    max-height: 420px;

    overflow: auto;
}

.cart_product_next{
    position: absolute;
    right: 68px;
    bottom: 42px;

    border: 2px solid #6da6f7;
    border-radius: 15px;
    padding: 18px;
    width: 180px;
    height: 54px;

    outline: none;
    background: none;
    cursor: pointer;

    font-weight: 700;
    font-size: 15px;
    color: #6da6f7;
}

.summary{
    border: 2px solid #6da6f7;
    border-radius: 15px;
    padding: 18px;
    width: 148px;
    height: 54px;

    font-weight: 700;
    font-size: 15px;
    color: #6da6f7;

    text-align: center;
}

.poducts_cart_container.sending{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 30px;
    width: 100%;

    max-height: 420px;

    overflow: auto;
}

.form_inputs_wrapper{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    gap: 30px;
}

.phone_name{
    border: 2px solid #6da6f7;
    border-radius: 15px;
    padding: 18px 20px;
    width: 100%;

    font-weight: 700;
    font-size: 15px;
    color: #6da6f7;

    outline: none;
}

input::placeholder, textarea::placeholder{
    font-weight: 700;
    font-size: 15px;
    color: #6da6f7;
}

.description{
    border: 2px solid #6da6f7;
    border-radius: 15px;
    padding: 18px 20px;
    width: 100%;
    height: 150px;

    outline: none;

    font-weight: 700;
    font-size: 15px;
    color: #6da6f7;

    resize: none;
}

.error{
    border: 2px solid #f86c6f !important;
}

.cart_product_description_modal{
    display: flex;
    justify-content: flex-start;
    gap: 5px;
}

.success{
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 100%;
    backdrop-filter: blur(20px);
    background: rgba(0, 0, 0, 0.4);

    display: flex;
    justify-content: center;
    align-items: center;
}

.HeaderDelivery{
    font-weight: 700;
    font-size: 30px;
    color: #6da6f7;
    margin-left: 5px;
}

.DescriptionDelivery ul li{
    font-weight: 700;
    font-size: 20px;
    color: #6da6f7;
    margin-left: 5px;
}

@media (max-width: 760px){
    .cart_product_wrapper{
        border-radius: 30px;
        padding: 20px 0px 0px 0px;
        width: 80%;
        height: 80vh;
    
        gap: 30px;
    
        padding-top: 65px;
        padding-bottom: 20px;
        padding-inline: 20px;
    
        background: #fff;
    }

    .cart_product.empty svg{
        width: 300px;
    }

    .cart_product_cross{
        top: 20px;
        right: 20px;
    }

    .HeaderDelivery{
        font-weight: 700;
        font-size: 20px;
        color: #6da6f7;
        margin-left: 5px;
    }
    
    .DescriptionDelivery ul li{
        font-weight: 700;
        font-size: 15px;
        color: #6da6f7;
        margin-left: 5px;
    }
    
}