.main_wrapper{
    max-width: 1920px;
    width: 100vw;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 50px;
}

.main_header_container{
    position: relative;
    display: flex;
    width: 100%;
    height: 709px;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 50px;
    box-shadow: 0 11px 21px 0 rgba(62, 100, 154, 0.25);
    background: #fff;
    padding: 70px 70px;

    background: #6da6f7;
    background-image: url("../../assets/backgroundWhite.svg");
    background-repeat: no-repeat;
    background-position: right;
}

.main_header_buttons_wrapper{
    width: 46%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    z-index: 1;

    gap: 20px;
}

.main_control_buttons button{
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
}

.main_control_buttons{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 100px;
}

.main_header_container a{
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
}

.main_header_text{
    width: 662px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 1;
}

.main_header_text p{
    font-weight: 700;
    font-size: 30px;
    color: #fff;
}

.main_header_text h1{
    font-weight: 700;
    font-size: 60px;
    color: #fff;
}

.main_header_container a{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 60px;
    width: 308px;
    height: 76px;
    box-shadow: 0 17px 18px 0 rgba(62, 100, 154, 0.5);
    background: #f86c6f;

    text-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
    font-weight: 700;
    font-size: 30px;
    color: #fff;
    z-index: 1;
}

.main_header_container a:active{
    box-shadow:none;
}


.main_control_button{
    filter: drop-shadow(0 13px 11px rgba(0, 0, 0, 0.25));
    background: none;
}

.main_info_container{
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #6da6f7;
    width: 100%;
    height: 320px;
    overflow: hidden;
}

.main_info_container p{
    z-index: 1;
    font-weight: 700;
    font-size: 30px;
    color: #fff;
    width: 801px;
    margin-left: 15.7%;
}

.main_control_button.Logo{
    width: 242px;
    height: 78px;

}

.main_control_button.Cart{
    width: 46px; 
    height: 41px;
}

.main_control_button.Phone{
    width: 41px; 
    height: 42px;
}

.main_header_white{
    position: absolute;
    top: 0;
    left: 46%;
    z-index: 0;
}

.main_control_button{
    background: none !important;
    width: unset !important;
    height: unset !important;
    box-shadow: none !important;
}

.main_control_button:active{
    filter: none;
}

.main_header_isometric{
    position: absolute;
    width: 723px;
    height: 669px;
    top: 20px;
    left: 61%;
}

.main_header_down{
    position: absolute;
    left: 0;
    top: 453px;
    width: 416px;
    height: 256px;
}

.main_header_top{
    position: absolute;
    width: 360px;
    height: 157px;
    top: 0;
    right: 1188px;
}

.main_info_flag{
    position: absolute;
    width: 211px;
    height: 242px;
    top: 0;
    right: 21.5%;
    filter: drop-shadow(0 13px 11px rgba(0, 0, 0, 0.25));
}

.main_info_down{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 273px;
    height: 239px;
    z-index: 0;
}

.main_info_right{
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    width: 294px;
    height: 289px;
}

.main_warning_container{
    position: relative;
    width: 100%;
    height: 393px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 30px;
    background: #f86c6f;
}

.main_warning_container p{
    margin-left: 799px;
    width: 680px;

    font-weight: 700;
    font-size: 30px;
    color: #fff;
}

.main_svg_container{
    position: relative;
    width: 100%;
    height: 250px;
    overflow: hidden;
}

.main_svg_container svg{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1366px;
    height: 250px;
}

.main_warning_sign{
    position: absolute;
    top: 70px;
    left: 425px;
    width: 277px;
    height: 253px;
    filter: drop-shadow(0 13px 11px rgba(0, 0, 0, 0.25));
}

.main_product_wrapper{
    width: 100%;
    box-shadow: 0 -11px 21px 0 rgba(62, 100, 154, 0.25), 0 11px 21px 0 rgba(62, 100, 154, 0.25);
    background: #fff;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
    padding-top: 146px;
    gap: 30px;
}

.main_product_container{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 60px 30px;

    padding-inline: 108px;

    max-width: 1270px;
   

    max-height: 1308px;
}

.pagination{
    display: flex;
    justify-content: flex-start;
    align-items: center;

    gap: 30px;
}

.pages_container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    border: 3px solid #6da6f7;
    border-radius: 18px;
    padding: 0px 30px;
}

.pages_container button{
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 24px;
    color: #6da6f7;
}

.pagination button{
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
}

.pagination_button {
    position: relative;
    width: 15px;
    height: 34px;
    text-align: center;
}

.pagination_button span{
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 0;
    height: 3px;
    background: #6da6f7;

}

.pagination_button.Active span{
    width: 100%;
}

.DeliveryBanner{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 100vw;
    backdrop-filter: blur(20px);
    background: rgba(0, 0, 0, 0.4);
    z-index: 2;
    transition: 0.5s;
    bottom: 0;

    font-weight: 700;
    font-size: 20px;
    color: #fff;

    padding-inline: 10px;

}

.DeliveryBanner button{
    border: none;
    background: none;
    outline: none;

    font-weight: 700;
    font-size: 20px;
    color: #6da6f7;
    margin-left: 5px;

    cursor: pointer;
}

