@font-face {
  font-family: 'Gilroy';
  src: url('./font/Gilroy-Bold.eot');
  src: local('./font/Gilroy Bold'), local('Gilroy-Bold'),
      url('./font/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
      url('./font/Gilroy-Bold.woff') format('woff'),
      url('./font/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
  font-family: "Gilroy";
  color: #fff;
}

body{
  background: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
}

#root{
  display: flex;
  justify-content: center;
  overflow-x: hidden;
}

.App{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
}

.AppWrapp{
display: flex;
justify-content: center;
flex-direction: column;
background-color: #fff;
width: 768px;
height: 100%;
}

html {
  scroll-behavior: smooth;
  height: 100%;
}

a {
  text-decoration: none;
  color: unset;
}

