.product_wrapper{
    border: 3px solid #6da6f7;
    border-radius: 11px;
    padding: 25.5px 16px;
    width: 241px;
    height: 376px;

    gap: 10px;

    box-shadow: 0 10px 17px 0 rgba(62, 100, 154, 0.25);
    background: #fff;

    font-weight: 700;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.product_wrapper img{
    border: 3px solid #6da6f7;
    border-radius: 11px;
    width: 194px;
    height: 194px;
    object-fit: cover;
}

.product_title{
    width: 194px;
    height: 54px;

    font-size: 15px;
    text-align: center;
    color: #6da6f7;

    display: flex;
    justify-content: center;
    align-items: center;
}

.product_price{
    width: 132px;
    height: 19px;

    display: flex;
    justify-content: space-between;
}

.product_price button{
    background: none;
    outline: none;
    cursor: pointer;
    border: none;
}

.product_price_container{
    font-weight: 800;
    font-size: 15px;
    text-align: center;
    color: #6da6f7;
}

.product_change_quantity{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
}

.product_change_quantity button{
    padding: 0 5px;
    border-radius: 7px;
    border: none;
    background: #6da6f7;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.product_input{
    width: 141px;
    height: 28px;

    border-radius: 9px;
    width: 135px;
    height: 22px;

    font-weight: 400;
    font-size: 15px;
    color: #6da6f7;

    border: 3px solid #6da6f7;
    text-align: center;
}