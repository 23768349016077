.admin_wrapper{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;

    padding-top: 100px;
    padding-inline: 70px;
}

.admin_wrapper a{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    text-align: center;
    font-size: 24px;
    text-align: center;
    color: #6da6f7;
}

.admin_container{
    padding-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 50px;
}

.admin_reduct_wrapper{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 50px;

    gap: 30px;
}