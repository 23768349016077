.footer_wrapper{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 70px;
    flex-direction: column;
    gap: 30px;

    box-shadow: 0 -11px 21px 0 rgba(62, 100, 154, 0.25);
    background: #6da6f7;
}

.footer_wrapper p, a{
    font-weight: 700;
    font-size: 24px;
    color: #fff;
}

.footer_info_wrapper a{
    font-weight: 700;
    font-size: 24px;
    color: #fff;

    width: unset !important;

    background: none !important;

    box-shadow: none !important;

    text-shadow: none !important;
}

.footer_info_wrapper{
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: row;   
    gap: 80px;
}

.footer_wrapper a{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 60px;
    width: 308px;
    height: 76px;
    box-shadow: 0 17px 18px 0 rgba(62, 100, 154, 0.5);
    background: #f86c6f;

    text-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
    font-weight: 700;
    font-size: 30px;
    color: #fff;
    z-index: 1;
    padding: 20px 70px;

    outline: none;
    border: none;
    cursor: pointer;
}

.linksTo a{
    height: unset !important;
    padding: 0 0;
}
.footer_wrapper a:active{
    box-shadow:none;
}

@media (max-width: 1650px){
    .footer_wrapper p, a{
        font-size: 20px;
    }

    .footer_info_wrapper a{
        font-size: 20px;
    }

    .footer_wrapper{
        padding: 50px;
        gap: 20px;
    }
}

@media (max-width: 1000px){
    .footer_wrapper p, a{
        font-size: 18px;
    }

    .footer_info_wrapper a{
        font-size: 18px;
    }

    .footer_wrapper{
        padding: 30px;
    }
}

@media (max-width: 760px){
    .footer_wrapper p, a{
        font-size: 18px;
    }

    .footer_info_wrapper a{
        font-size: 18px;
    }

    .footer_wrapper{
        padding: 30px 20px;
    }
    
    .footer_info_wrapper{
        align-items: flex-start;
        flex-direction: column;   
        gap: 10px;
    }
}


